export const GAME_NAME = 'Multifights'
export const ENVIROMENT: string = 'mainnet'
export const API_TIMEOUT = 10000
export const WALLET_CONNECT_PROJECT_V2 = 'c7abf993e449e768fa89064b77cc2230'
export const STORAGE_OBJECT_URL =
    'https://media.elrond.com/nfts/asset/bafybeigqwcyrcl4rj5iahlcopnjijboefyhs55lparlvrkci5cbqkwl5ni'
export const API_URL = 'https://api.multi-fights.com'
export const WS_URL = 'https://api.multi-fights.com'
export const MULTIVERSX_API_URL = 'https://api.multiversx.com'
export const JWT_SECRET = process.env.REACT_APP_JWT_SECRET

export const WHITELIST_ADDRESSES = [
    'erd1q07w9xm8avd7kwj3cgn3xrnhzg5da7e3vg7dv6gs3npyql0jpq9ss35a20',
    'erd1368tpzxwzsgz3vtd58s2dyukfwwcmn0skpajt5qp0tk9u8y284zql5fmlg',
    'erd16rnjqmuw5pdpfm4zevdf9xdnns0ex94pvjnjn7ezkp9a56ahnpjqzm8d2a',
    'erd10x0a7x47s8gj9f4tupw045tw5cxd45czlpazcceh8pyg8t33el7q9448fm',
    'erd1usmpufemgpv9ynnyjv59g4f8smtutunz96zscy882vtvskm0avxsnv3ds7',
    'erd1lfyvqlsjfll8qrf22dl3jhgehqlfkruqg35zktyuvfnsv5nldess3rjwff',
    'erd1fjdgh6dssqvm54gqeufuzeflk6g09t3qyjr4sf69jau3aefcgnmq5sep42',
    'erd1r04ycv8lyr6kzfnj8qyw36chm9tta0fecw5akrgaeycm50wcanjsnuu3lq',
    'erd1h4l7sv3xxqh86qvqptpegfu9extpl4f2ndp5trts04hy555dr06q6y88gp',
    'erd10t9kl0fnuezzk7hunc3nlp2n723zhspvhe4qzhhcahfy9qtaa0ts6kswv7',
    'erd1a5k2mejhxu7ynnuwzx65lfky06km8hvd3gxhh8yctg05c33df42quurenr',
    'erd1ek3nqfqn08a9xfvcfm3wr49nhe9svfj4zdux2gh02acr4ywrzcls59tr7k',
    'erd1exfrwd2ym7wmllcqymtue2uk6m4xlwx7ra0f20nyuxp6u9clxg8s5087ms',
    'erd194445p36wgdp4ga4f9vg6yjr7rsr5eje64q0zvv0r8wkjxqcm40se6ytl2',
    'erd1qtmdcne7xzq7jp8yzuc6k5a7x72angfr4mvn0cly9adwsc0hvwsqfne4xz',
    'erd1u2uut6nyy22slqj9xs8p73jl0csjxcql3ydez85hvqx2cp353dcqymcsg8',
]

// WEB3
export const CHAIN_ID = '1'

// Token
export const TOKEN_IDENTIFIER = 'MUF-733473'
export const TOKEN_DECIMALS = 18

// SC
export const GAME_SC_ADDRESS = 'erd1qqqqqqqqqqqqqpgqekyv7xnr7gd6kwew73mf2w4qdz6utv50lzms83rwtd'

// Deposit endpoints
export const DEPOSIT_TOKEN = 'depositToken'

// Wheel endpoints
export const DEFAULT_WHEEL_BLUR = '0px'
export const FREE_SPIN = 'freeSpin'
export const PREMIUM_SPIN = 'premiumSpin'
export const PREMIUM_SPIN_FEE = 0
